import React, { FunctionComponent, ReactElement } from 'react';

const BodyButton: FunctionComponent = ({
  primary: {
    button_heading,
    button_link: { url, target },
  },
}): ReactElement => {
  return (
    <div className="flex flex-col items-center justify-center mt-8">
      <a href={url} target={target} className="btn prose-btn">
        {button_heading}
      </a>
    </div>
  );
};

export default BodyButton;
