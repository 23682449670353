import React, { FunctionComponent, ReactElement } from 'react';
import ImageCaption from '~/components/Blog/Post/ImageCaption';

const BodyImage: FunctionComponent = ({
  className = '',
  primary: {
    image: { alt, url },
  },
}): ReactElement => {
  return (
    <div className={className}>
      <img src={url} alt={alt} />
      {alt && <ImageCaption className="px-6 sm:px-0">{alt}</ImageCaption>}
    </div>
  );
};

export default BodyImage;
