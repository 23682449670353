import moment from 'moment';
import React from 'react';
import BodyVideoModal from '~/components/Blog/Post/BodyVideoModal';

export default function VideoHero({
  first_publication_date,
  data: {
    author,
    title,
    hero_image_max_width,
    hero_image,
    embed,
    source,
    placeholder_image,
  },
  categoryName,
}) {
  return (
    <>
      <div className="max-w-3xl mx-auto mb-16">
        <h5 className="mb-5 text-15px">
          {author?.document?.data?.title?.text && (
            <>
              <span className="font-bold">
                Written by {author?.document?.data?.title?.text}
              </span>
              <span> | </span>
            </>
          )}
          {first_publication_date &&
            moment(first_publication_date).format('MMM d, y')}
        </h5>
        <h1 className="font-blog-serif">{title.text}</h1>
        <span className="text-white pointer-events-none heading-background heading-background--hero font-blog-serif">
          {categoryName}
        </span>
      </div>

      <div
        className={`image-container mx-auto w-full max-w-${hero_image_max_width}`}
      >
        <BodyVideoModal
          primary={{
            embed,
            source,
            placeholder_image: placeholder_image || hero_image,
          }}
          className=""
        />
      </div>
    </>
  );
}
