import React, { FunctionComponent, ReactElement } from 'react';
import BodyImage from '~/components/Blog/Post/BodyImage';

const BodyTwoColImages: FunctionComponent = ({
  primary: { image, image_2, image_offset = 'left' },
}): ReactElement => {
  let lWidth = 'md:w-1/2';
  let rWidth = 'md:w-1/2';

  if (image_offset === 'left') {
    lWidth = 'md:w-7/12';
    rWidth = 'md:w-5/12';
  }

  if (image_offset === 'right') {
    lWidth = 'md:w-5/12';
    rWidth = 'md:w-7/12';
  }

  return (
    <div className="lg:-mx-20 py-2 md:-mx-12 -mx-6">
      <div className="flex flex-wrap items-center -ml-12">
        <div className={`pl-12 w-full ${lWidth}`}>
          <BodyImage primary={{ image: image }} />
        </div>
        <div className={`pl-12 w-full ${rWidth}`}>
          <BodyImage primary={{ image: image_2 }} />
        </div>
      </div>
    </div>
  );
};

export default BodyTwoColImages;
