import React, { FunctionComponent, ReactElement } from 'react';
import QuoteIcon from '~/images/blog/quote-icon.svg';

const BodyTestimonial: FunctionComponent = ({
  primary: {
    cite_name = '',
    cite_role = '',
    quote: { text = '' },
  },
}): ReactElement => {
  return (
    <div className="pt-6 text-center xl:-mx-32 md:-mx-16">
      <QuoteIcon className="w-16 mb-5 mx-auto" />
      <div
        className="font-blog-serif text-red leading-normal text-30px mb-8"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <cite className="text-xs not-italic block">
        <div className="font-bold">{cite_name}</div>
        {cite_role && <div className="">{cite_role}</div>}
      </cite>
    </div>
  );
};

export default BodyTestimonial;
