import { ErrorMessage, Formik } from 'formik';
import { Link } from 'gatsby';
import React from 'react';
import * as Yup from 'yup';
import Checkbox from '~/components/Checkbox';

export default function GatedForm({ setGatedFormSent }) {
  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    company: '',
    terms: false,
  };

  const schema = Yup.object().shape({
    firstname: Yup.string().required('This field is required'),
    lastname: Yup.string().required('This field is required'),
    email: Yup.string()
      .required('This field is required')
      .email('Please provide a valid email address'),
    company: Yup.string().required('This field is required'),
    terms: Yup.bool().oneOf([true], 'You need to accept the privacy policy'),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const path =
      process.env.NODE_ENV === 'production'
        ? '/7220198/4ed68eb6-91ac-4a53-b287-cb7a12a8860b'
        : '/8765047/223bcb20-9027-457c-acf4-2d3fff263bdf';

    // const url = `https://api.hsforms.com/submissions/v3/integration/submit${path}`;
    // TODO: get form url
    setGatedFormSent(true);

    // try {
    //   const response = await submitForm(url, values);
    //   setGatedFormSent(response.status === 'success');
    // } catch (error) {
    //   setSubmitting(false);
    //   setGatedFormSent(false);
    // }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({
        values: { firstname, lastname, email, company, terms },
        setFieldValue,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <form className="gated-form space-y-6" onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-6 lg:flex-row lg:space-y-0 lg:space-x-6">
            <div className="w-full lg:w-1/2">
              <input
                className="w-full py-2.5 border-b leading-8 h-auto rounded-lg"
                type="text"
                name="firstname"
                value={firstname}
                placeholder="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage
                className="text-xxs text-red "
                name="firstname"
                component="div"
              />
            </div>
            <div className="w-full lg:w-1/2">
              <input
                className="w-full py-2.5 border-b leading-8 h-auto rounded-lg"
                type="text"
                name="lastname"
                value={lastname}
                placeholder="Last Name"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage
                className="text-xxs text-red "
                name="lastname"
                component="div"
              />
            </div>
          </div>
          <div>
            <input
              className="w-full py-2.5 border-b leading-8 h-auto rounded-lg"
              type="email"
              name="email"
              value={email}
              placeholder="Email Address"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage
              className="text-xxs text-red "
              name="email"
              component="div"
            />
          </div>
          <div>
            <input
              className="w-full py-2.5 border-b leading-8 h-auto rounded-lg"
              type="text"
              name="company"
              value={company}
              placeholder="Company"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <ErrorMessage
              className="text-xxs text-red "
              name="company"
              component="div"
            />
          </div>
          <div>
            <div className="flex items-center tracking-tighter text-mid-grey text-xxs">
              <Checkbox
                className="mr-4 rounded"
                checked={terms}
                onClick={() => setFieldValue('terms', !terms)}
              />
              <span onClick={() => setFieldValue('terms', !terms)}>
                I agree to the
              </span>
              <Link
                className="ml-1 underline text-paragraph"
                to="/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
              .
            </div>
            <ErrorMessage
              className="text-xxs text-red "
              name="terms"
              component="div"
            />
          </div>
          <button
            type="submit"
            className="mb-16 u-btn u-btn--dark focus:outline-none"
            disabled={isSubmitting}
          >
            {!isSubmitting ? 'Download now' : 'Submitting...'}
          </button>
        </form>
      )}
    </Formik>
  );
}
