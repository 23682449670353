import React, { FunctionComponent, ReactElement } from 'react';

const ImageCaption: FunctionComponent = ({
  className = '',
  children,
}): ReactElement => {
  return (
    children && (
      <div
        className={`pb-4 -mt-4 text-xs sm:text-sm text-blog-primary border-b border-black ${className}`}
      >
        {children}
      </div>
    )
  );
};

export default ImageCaption;
