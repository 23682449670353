import Image from 'gatsby-image';
import moment from 'moment';
import React from 'react';

export default function StandardHero({
  first_publication_date,
  data: { author, title, hero_image_max_width, hero_image },
  categoryName,
}) {
  return (
    <>
      <div className="max-w-3xl mx-auto mb-16">
        <h5 className="mb-5 text-15px">
          {author?.document?.data?.title?.text && (
            <>
              <span className="font-bold">
                Written by {author?.document?.data?.title?.text}
              </span>
              <span> | </span>
            </>
          )}
          {first_publication_date &&
            moment(first_publication_date).format('MMM d, y')}
        </h5>
        <h1 className="font-blog-serif">{title.text}</h1>
        <span className="heading-background heading-background--hero font-blog-serif text-white pointer-events-none">
          {categoryName}
        </span>
      </div>

      <div
        className={`image-container mx-auto w-full max-w-${hero_image_max_width}`}
      >
        <Image fluid={hero_image.fluid} />
      </div>
    </>
  );
}
