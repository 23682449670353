import { AnimatePresence, motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';
import moment from 'moment';
import React, { useState } from 'react';
import GatedForm from '~/components/Blog/Post/GatedForm';

export default function DownloadHero({
  first_publication_date,
  data: {
    author,
    title,
    hero_image,
    download_hero_image,
    download_hero_description,
    download_file,
    gated_content = false,
  },
  categoryName,
}) {
  const [gatedFormSent, setGatedFormSent] = useState(false);
  const { prismicOptions } = useStaticQuery(graphql`
    query GatedContentQuery {
      prismicOptions {
        data {
          gated_form_heading
          gated_form_confirmation_heading
          gated_form_confirmation_content
          download_button_heading
        }
      }
    }
  `);
  return (
    <div
      className={`grid md:grid-cols-2 gap-12 text-left mb-24 ${!gated_content &&
        'items-center'}`}
    >
      <div>
        {gated_content && (
          <Image
            fluid={download_hero_image?.fluid || hero_image.fluid}
            className="w-56 mb-10 relative z-10"
          />
        )}

        <div>
          <h5 className="mb-5 text-15px">
            {author?.document?.data?.title?.text && (
              <>
                <span className="font-bold">{categoryName}</span>
                <span> | </span>
              </>
            )}
            {first_publication_date &&
              moment(first_publication_date).format('MMM d, y')}
          </h5>
          <h1 className="font-blog-serif">{title.text}</h1>
          {download_hero_description && (
            <p
              dangerouslySetInnerHTML={{ __html: download_hero_description }}
            />
          )}

          {!gated_content && download_file?.url?.length > 0 && (
            <a href={download_file.url} download className="btn relative z-10">
              {prismicOptions.data.download_button_heading}
            </a>
          )}
        </div>
        <span className="heading-background heading-background--hero font-blog-serif text-white pointer-events-none">
          {categoryName}
        </span>
      </div>

      <div>
        {gated_content && (
          <AnimatePresence>
            <div className="p-12 bg-white relative">
              <div className="max-w-md mx-auto">
                <h3 className="font-blog-serif">
                  {!gatedFormSent ? (
                    <motion.div
                      key={'heading-before'}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      {prismicOptions.data.gated_form_heading}
                    </motion.div>
                  ) : (
                    <motion.div
                      key={'heading-after'}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      {prismicOptions.data.gated_form_confirmation_heading}
                    </motion.div>
                  )}
                </h3>
                {gatedFormSent && (
                  <motion.p
                    key={'heading-after'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    dangerouslySetInnerHTML={{
                      __html:
                        prismicOptions.data.gated_form_confirmation_content,
                    }}
                  />
                )}

                {!gatedFormSent ? (
                  <motion.div
                    key={'gated-form'}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <GatedForm setGatedFormSent={setGatedFormSent} />
                  </motion.div>
                ) : (
                  <motion.div
                    key={'download-button'}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <a
                      href={download_file.url}
                      download
                      className="btn relative z-10 mt-6"
                    >
                      {prismicOptions.data.download_button_heading}
                    </a>
                  </motion.div>
                )}
              </div>
            </div>
          </AnimatePresence>
        )}
        {!gated_content && (
          <Image
            fluid={download_hero_image?.fluid || hero_image.fluid}
            className="max-w-xxs md:mx-auto"
          />
        )}
      </div>
    </div>
  );
}
