import React, { FunctionComponent, ReactElement } from 'react';
import BodyImage from '~/components/Blog/Post/BodyImage';

const BodyTwoColImageText: FunctionComponent = ({
  primary: {
    image,
    reverse = false,
    text: { html = '' },
  },
}): ReactElement => {
  return (
    <div className="pt-6 pb-8 md:-mx-12 lg:-mx-24 xl:-ml-56">
      <div className="flex flex-wrap items-center -ml-12">
        <div className={`pl-12 md:w-6/12 ${reverse && 'md:order-1'}`}>
          <BodyImage primary={{ image: image }} />
        </div>
        <div className="pl-12 md:w-6/12">
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
    </div>
  );
};

export default BodyTwoColImageText;
