import React, { FunctionComponent, ReactElement } from 'react';

const BodyText: FunctionComponent<{ primary: { text: { html: string } } }> = ({
  primary: {
    text: { html },
  },
}): ReactElement => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default BodyText;
