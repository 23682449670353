import Image from 'gatsby-image';
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import PlayIcon from '~/images/blog/play-video.svg';

const BodyVideoModal: FunctionComponent = ({
  primary: {
    placeholder_image,
    source = 'youtube',
    embed: { embed_url = '', thumbnail_url = '' },
  },
  className = 'py-2 xl:-mx-56 lg:-mx-32',
}): ReactElement => {
  const [isOpen, setOpen] = useState(false);
  const [videoID, setVideoID] = useState(false);

  useEffect(() => {
    if (!embed_url) return;
    let videoID;

    if (source === 'youtube') {
      const videoSplit = embed_url.split('v=');
      if (videoSplit.length >= 2) {
        videoID = videoSplit[1];
        const ampersandPosition = videoID.indexOf('&');
        if (ampersandPosition !== -1) {
          videoID = videoID.substring(0, ampersandPosition);
        }
      }
    } else if (source === 'vimeo') {
      // https://stackoverflow.com/a/13286930
      // eslint-disable-next-line no-useless-escape
      const vimeoRegex = /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
      const matches = embed_url.match(vimeoRegex);
      if (matches && matches.length >= 3) {
        videoID = matches[3];
      }
    }

    setVideoID(videoID);
  }, [embed_url]);

  return (
    <>
      <div className={`${className} overflow-hidden`}>
        <div className="relative">
          <Image fluid={placeholder_image.fluid} />
          <div
            className="absolute inset-0 flex items-center justify-center cursor-pointer"
            onClick={() => setOpen(true)}
          >
            <PlayIcon className="w-40 transition-transform duration-150 transform hover:scale-105" />
          </div>
          {placeholder_image.alt && (
            <div className="absolute bottom-0 left-0 flex items-center px-4 py-2 text-xs font-semibold text-white bg-black pointer-events-none">
              <div className="mr-2">
                <svg
                  className="relative w-3"
                  style={{ bottom: '1px' }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 14 14"
                >
                  <path
                    d="M7 14a7 7 0 1 0-7-7 7 7 0 0 0 7 7zM5.5 9.598L10 7 5.5 4.402z"
                    fill="#fff"
                    fillRule="evenodd"
                  />
                </svg>
              </div>
              <div>{placeholder_image.alt}</div>
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <ModalVideo
          channel={source}
          autoplay={1}
          isOpen={isOpen}
          videoId={videoID}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default BodyVideoModal;
