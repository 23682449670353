import { graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import { withPreview } from 'gatsby-source-prismic';
import React, { FunctionComponent, ReactElement } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import Article from '~/components/Blog/Article';
import BodyButton from '~/components/Blog/Post/BodyButton';
import BodyHTML from '~/components/Blog/Post/BodyHTML';
import BodyImage from '~/components/Blog/Post/BodyImage';
import BodyTestimonial from '~/components/Blog/Post/BodyTestimonial';
import BodyText from '~/components/Blog/Post/BodyText';
import BodyTwoColImages from '~/components/Blog/Post/BodyTwoColImages';
import BodyTwoColImageText from '~/components/Blog/Post/BodyTwoColImageText';
import BodyVideoModal from '~/components/Blog/Post/BodyVideoModal';
import DownloadHero from '~/components/Blog/Post/DownloadHero';
import StandardHero from '~/components/Blog/Post/StandardHero';
import VideoHero from '~/components/Blog/Post/VideoHero';
import BlogLayout from '~/components/BlogLayout';
import SEO from '~/components/Global/SEO';
import FacebookIcon from '~/images/blog/share/facebook.svg';
import LinkedInIcon from '~/images/blog/share/linkedin.svg';
import TwitterIcon from '~/images/blog/share/twitter.svg';

interface BlogPostProps {
  pageContext: object;
}

const sections = {
  PrismicBlogPostBodyText: BodyText,
  PrismicBlogPostBodyImage: BodyImage,
  PrismicBlogPostBodyTwoColImages: BodyTwoColImages,
  PrismicBlogPostBodyTwoColImageText: BodyTwoColImageText,
  PrismicBlogPostBodyVideo: BodyVideoModal,
  PrismicBlogPostBodyTestimonial: BodyTestimonial,
  PrismicBlogPostBodyButton: BodyButton,
  PrismicBlogPostBodyHtml: BodyHTML,
};

const BlogPost: FunctionComponent<BlogPostProps> = ({ data }): ReactElement => {
  const shareURL = typeof window !== 'undefined' ? window.location.href : '';
  const { prismicBlogPost } = data;

  const {
    data: { title, teaser, author, category, body, related_posts },
    id,
  } = prismicBlogPost;

  const categoryName = category?.document?.data?.title?.text;
  const categorySlug = category?.document?.uid;

  const colourMap = {
    articles: 'bg-blog-blue',
    events: 'bg-blog-green',
    causes: 'bg-blog-green-2',
    'case-studies': 'bg-blog-yellow',
    videos: 'bg-dark-blue',
    downloads: 'bg-blog-yellow-2',
  };
  const backgroundColor = colourMap[categorySlug] || 'bg-blog-blue';

  return (
    <BlogLayout>
      <SEO title={title.text} />

      <section
        className={`hero pt-32 md:pt-40 overflow-hidden ${backgroundColor}`}
      >
        <div className="container relative flex flex-col justify-center text-center">
          {categorySlug !== 'downloads' && categorySlug !== 'videos' && (
            <StandardHero {...prismicBlogPost} categoryName={categoryName} />
          )}
          {categorySlug === 'downloads' && (
            <DownloadHero {...prismicBlogPost} categoryName={categoryName} />
          )}

          {categorySlug === 'videos' && (
            <VideoHero {...prismicBlogPost} categoryName={categoryName} />
          )}
        </div>
      </section>

      <main className="py-16 md:py-24">
        <div className="container">
          <div className="max-w-2xl mx-auto md:px-5">
            <div className="prose prose-lg prose-red">
              {body.map(({ __typename, ...layoutProps }, index) => {
                if (sections[__typename]) {
                  return React.createElement(sections[__typename], {
                    key: `post-${id}-${index}`,
                    ...layoutProps,
                    className:
                      __typename === 'PrismicBlogPostBodyImage'
                        ? 'lg:-mx-16 md:-mx-12 -mx-6'
                        : '',
                  });
                }
              })}
            </div>
            <section className="mt-12 text-sm">
              <div className="mb-2 text-sm opacity-75">Written by</div>
              <div className="flex items-center author">
                {author?.document?.data?.profile_image?.fluid && (
                  <div className="w-16 mr-4 overflow-hidden rounded-circle ">
                    <Image
                      fluid={author?.document?.data?.profile_image?.fluid}
                      className="object-cover w-full h-full"
                    />
                  </div>
                )}
                <div>
                  <h5 className="font-semibold leading-snug">
                    {author?.document?.data?.title?.text}
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: author?.document?.data?.role,
                    }}
                    className="leading-snug"
                  />
                </div>
              </div>
            </section>
            <div className="flex items-center justify-center mt-12 -ml-4 share-icons">
              <div className="pl-4 transition-opacity duration-75 hover:opacity-75">
                <FacebookShareButton url={shareURL}>
                  <FacebookIcon className="w-8" />
                </FacebookShareButton>
              </div>
              <div className="pl-4 transition-opacity duration-75 hover:opacity-75">
                <TwitterShareButton url={shareURL}>
                  <TwitterIcon className="w-8" />
                </TwitterShareButton>
              </div>
              <div className="pl-4 transition-opacity duration-75 hover:opacity-75">
                <LinkedinShareButton
                  url={shareURL}
                  title={title?.text}
                  summary={teaser}
                  source={`Powered by Percent`}
                >
                  <LinkedInIcon className="w-8" />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>
      </main>

      {related_posts?.length > 0 && (
        <section className="overflow-hidden related bg-lgrey">
          <div className="container">
            <div className="relative py-20 md:py-24">
              <header className="flex flex-wrap items-end justify-between w-full mb-12 overflow-hidden">
                <div
                  className="text-white pointer-events-none heading-background heading-background--hero font-blog-serif"
                  style={{ top: '-7%', left: '-15%', opacity: 0.75 }}
                >
                  Related
                </div>
                <h3 className="relative mr-8 leading-none font-blog-serif text-60px xl:text-80px">
                  Related <div className="block ml-20">{categoryName}</div>
                </h3>
                <Link
                  className="inline-block font-bold arrow-right"
                  to={`/blog/category/${categorySlug}`}
                >
                  View more {categoryName}
                </Link>
              </header>
              <div className="grid gap-12 md:grid-cols-3">
                {related_posts.map(({ related: { document } }, i) => (
                  <Article
                    key={`related${i}`}
                    className={`${i % 2 === 0 ? 'md:mt-12' : ''}`}
                    {...document}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      )}
    </BlogLayout>
  );
};

export default withPreview(BlogPost);

export const pageQuery = graphql`
  query BlogPost($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      id
      first_publication_date
      data {
        title {
          text
        }
        teaser
        hero_image_max_width
        hero_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        source
        embed {
          embed_url
        }

        placeholder_image {
          alt
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }

        # Download Fields
        download_hero_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        download_file {
          url
        }
        download_hero_description
        gated_content

        # Categories
        category {
          document {
            ... on PrismicCategory {
              uid
              data {
                title {
                  text
                }
              }
            }
          }
        }

        # Author
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                title {
                  text
                }
                role
                profile_image {
                  fluid {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
            }
          }
        }

        # Related
        related_posts {
          related {
            document {
              __typename
              ... on PrismicBlogPost {
                ...PostPreview
              }
            }
          }
        }

        # Body
        body {
          ... on PrismicBlogPostBodyText {
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyImage {
            slice_type
            primary {
              image {
                alt
                url
              }
            }
          }
          ... on PrismicBlogPostBodyTestimonial {
            slice_type
            primary {
              cite_name
              cite_role
              quote {
                text
              }
            }
          }
          ... on PrismicBlogPostBodyVideo {
            slice_type
            primary {
              placeholder_image {
                alt
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              source
              embed {
                embed_url
                thumbnail_url
              }
            }
          }
          ... on PrismicBlogPostBodyTwoColImageText {
            slice_type
            primary {
              image {
                alt
                url
              }
              reverse
              text {
                html
              }
            }
          }
          ... on PrismicBlogPostBodyTwoColImages {
            slice_type
            primary {
              image_offset
              image {
                alt
                url
              }
              image_2 {
                alt
                url
              }
            }
          }
          ... on PrismicBlogPostBodyButton {
            primary {
              button_heading
              button_link {
                url
                target
              }
            }
          }

          ... on PrismicBlogPostBodyHtml {
            primary {
              html
            }
          }
        }
      }
    }
  }
`;
